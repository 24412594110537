import CogWheelButton from './CogWheelButton';
import Modal from './Modal';
import { getSiteConfig, getLocale } from './utils';

const ariaLabelWhyLocale = getLocale() === 'SV' ? 'Varför ser du denna annons?' : 'Hvorfor ser du denne annonsen?';

export default class AdTransparencyDecorator {
    constructor(apntag) {
        const siteConfig = getSiteConfig();
        this.apntag = apntag;
        this.decoratedAds = [];
        this.modal = new Modal(getLocale(), this.apntag, siteConfig);
        this.useCustomColor = siteConfig.useCustomColor;
    }

    // Here we handle ads of type "native" without iFrames.
    static findNativeAdPos(tagTargetId, auctionId) { //eslint-disable-line
        return undefined;
        // not decided if we should do anything else here
    }


    static getIFrameDocument(iframe) {
        // Check if ad is served using "safeframe"
        const iframeSrc = iframe.getAttribute('src');
        if (iframeSrc && iframeSrc.indexOf('safeframe') !== -1) {
            return false;
        }
        let html = null;
        let iFrameDocument;
        try {
            // Deal with older browsers, getting the document
            iFrameDocument = iframe.contentDocument || iframe.contentWindow.document;
            // Again check if ad is served using "safeframe"
            // We are not allowed to reach inside of the iFrame if it is an "safeframe".
            // Error will rise if we are not allowed.
            html = iFrameDocument.body.innerHTML;
        } catch (err) {
            // Do nothing, the ad was probably served using "safeframe"
        }
        return html !== null ? iFrameDocument : false;
    }

    findIframeAdPos(tagTargetId, auctionId, creativeId) {
        // Find existing ad wrapping element by Apnexus target id
        const adWrappingElement = document.getElementById(tagTargetId);
        if (adWrappingElement) {
            const adIframe = adWrappingElement.querySelector('iframe');
            if (adIframe) {
                const iframeDoc = AdTransparencyDecorator.getIFrameDocument(adIframe);
                if (iframeDoc) {
                    const adBody = iframeDoc.body;
                    if (adBody) {
                        this.appendCogWheel(tagTargetId, auctionId, adBody, 'iframe', this.useCustomColor, creativeId);
                    } else {
                        console.log(tagTargetId, 'no body in ad iframe'); // eslint-disable-line
                    }
                } else {
                    this.appendCogWheel(tagTargetId, auctionId, adWrappingElement, 'safeframe', this.useCustomColor, creativeId);
                }
            } else {
                console.log(tagTargetId, 'no ad iframe found'); // eslint-disable-line
            }
        } else {
            console.log(tagTargetId, 'no adwrapping element'); // eslint-disable-line
        }
        return undefined;
    }

    appendCogWheel = (tagTargetId, auctionId, buttonElement, type, useCustomColor = false, creativeId) => {
        const button = buttonElement;
        const cogWheelElement = new CogWheelButton(tagTargetId, type, useCustomColor, ariaLabelWhyLocale).getButton();
        this.removeOldCogWheel(cogWheelElement.id);
        button.appendChild(cogWheelElement);
        button.onclick = this.modal.handleClick;
        this.decoratedAds.push({ auctionId, creativeId });
    };

    removeOldCogWheel = (id) => {
        const oldCogWheel = document.getElementById(id);
        if (oldCogWheel) {
            oldCogWheel.remove();
        }
    };

    static shouldAppendCogWheel(toAppend) {
        return toAppend !== null && !toAppend.classList.contains('disable-cogwheel');
    }

    setupButton(a) {
        // Field "adResponse" does not exist if the response from ApNexus tells us ad is "nobid: true"
        const { targetId: tagTargetId, adResponse: { ads = [], auction_id: auctionId } = {} } = this.apntag.requests.tags[a];
        if (ads.length > 0) {
            const { ad_type: type, creative_id: creativeId } = ads[0];
            const bySitePredifinedElement = document.getElementById(`${tagTargetId}-button`);

            if (!AdTransparencyDecorator.shouldAppendCogWheel(document.getElementById(tagTargetId))) {
                return false;
            }

            if (bySitePredifinedElement) {
                const color = (bySitePredifinedElement.dataset && bySitePredifinedElement.dataset.customColor) || this.useCustomColor;
                this.appendCogWheel(tagTargetId, auctionId, bySitePredifinedElement, 'predefinedElement', color, creativeId);
            } else if (type === 'native') {
                AdTransparencyDecorator.findNativeAdPos(tagTargetId, auctionId);
            } else {
                this.findIframeAdPos(tagTargetId, auctionId, creativeId);
            }
        } else {
            // Added becuase of weird behaviour on native ads on https://www.avisa-st.no/
            console.log(tagTargetId, `WARNING no ads found in apntag.requests.tags['${tagTargetId}'].adResponse.ads`); // eslint-disable-line
        }
    }

    decorateAds() {
        const { requests: { tags = {} } = {} } = this.apntag;
        Object.keys(tags).forEach(tag => this.setupButton(tag));
    }

    // Needed for sites running ast.js < 0.10.0 where targetId is not included in adLoaded callback adObject
    getTargetIdByAuctionId(adLoadedAuctionId) {
        const { tags } = this.apntag.requests;

        const matchingTargetIds = Object.keys(tags).filter((tag) => {
            const { adResponse: { auction_id: auctionId } = {} } = tags[tag];

            return auctionId === adLoadedAuctionId;
        });

        return matchingTargetIds.length > 0 && matchingTargetIds[0];
    }

    adLoadedCallback = ({ targetId: adLoadedTargetId, auctionId: adLoadedAuctionId, creativeId: adLoadedCreativeId }) => {
        const targetId = adLoadedTargetId || this.getTargetIdByAuctionId(adLoadedAuctionId);
        // Array.prototype.find() not supported in IE, using Array.prototype.some() instead.
        const isDecorated = this.decoratedAds.some(item => item.auctionId === adLoadedAuctionId && item.creativeId === adLoadedCreativeId);

        if (targetId && adLoadedAuctionId && adLoadedCreativeId && !isDecorated) {
            this.setupButton(targetId);
        }
    };
}
