/* eslint-disable no-undef */
import PulseTracker from './Tracker';
import lazyStyles from './styles/modal.lazy.scss';

import { getCreativeId, getElementDimensions, getPulse2DataCookieUUID, luckyUser, sendPulseEvent, getSiteConfig } from './utils';


export default class Modal {
    constructor(
        locale,
        apntag,
        {
            name: siteName,
            links: linksOverride,
            polaris: isPolarisSite = false,
            marketplace: isMarketplaceSite = false,
        },
    ) {
        this.config = window.gdpr || {};
        this.apntag = apntag;
        this.siteName = siteName;
        this.locale = locale;
        this.languages = {
            title: {
                SV: `${this.siteName} är en del av `,
                NO: `${this.siteName} er en del av `,
            },
            family: {
                SV: 'Schibsted-familjen',
                NO: 'Schibsted-familien',
            },
            close: {
                SV: 'Stäng',
                NO: 'Lukk',
            },
            why: {
                SV: 'Varför ser du denna annons?',
                NO: 'Hvorfor ser du denne annonsen?',
            },
            whyexplained: {
                SV: 'Du ser denna annons eftersom sajten du besöker till stor del finansieras av annonsintäkter. Vissa annonser ser du för att vi tror att de kan intressera dig. Detta antagande baserar vi på din tidigare aktivitet på Schibsteds sajter och appar.',
                NO: 'Du ser denne annonsen ettersom nettstedet du besøker til stor del finansieres av annonseinntekter. Noen annonser ser du fordi vi tror de kan interessere deg. Vi antar dette basert på din tidligere aktivitet på Schibsteds nettsteder og apper.',
            },
            manageheading: {
                SV: 'Hantera dina annonsinställningar',
                NO: 'Administrer dine annonseinnstillinger',
            },
            takecontrol: {
                SV: 'Styr själv över vilka annonser du ser genom att ändra dina inställningar.',
                NO: 'Du kan selv styre hvilke annonser du ser ved å endre dine innstillinger.',
            },
            aboutschibsted: {
                SV: 'Om Schibsted och annonsering',
                NO: 'Om Schibsted og annonsering',
            },
            schibstedexplain: {
                SV: 'Schibsted är en etablerad mediekoncern med huvudkontor i Oslo, som äger sajter som till exempel Blocket, Svenska Dagbladet, Omni, Aftonbladet och Prisjakt.',
                NO: 'Schibsted er et etablert mediekonsern med hovedkontor i Oslo, som eier nettsteder som for eksempel VG, Aftenposten, Finn og E24.',
            },
            readmoreschibstedlinktext: {
                SV: 'Läs mer om annonsering på Schibsted',
                NO: 'Les mer om annonsering på Schibsted',
            },
            smokeTest: {
                SV: '',
                NO: 'Skjul annonsen midlertidig',
            },
            smokeTestURL: {
                SV: '',
                NO: 'https://docs.google.com/forms/d/e/1FAIpQLSdS1Mbdz1Qw870JNRoYK3qp5u0EFta9H-NJywi-yncUc7duQg/viewform',
            },
            hideAd: {
                SV: 'Dölj din annons tillfälligt',
                NO: 'Skjul annonsen midlertidig',
            },
            annonseId: {
                SV: 'Annonsens ID-nummer',
                NO: 'Annonsens ID-nummer',
            },
        };
        this.titleText = isPolarisSite ? `${this.siteName} samarbeider med ` : `${this.languages.title[this.locale]}`;
        this.whyText = isMarketplaceSite ? 'Varför ser du denna reklam?' : `${this.languages.why[this.locale]}`;
        this.whyexplainedText = isPolarisSite
            ? 'Du ser denne annonsen ettersom nettstedet du besøker til stor del finansieres av annonseinntekter. Noen annonser ser du fordi vi tror de kan interessere deg. Vi antar dette basert på din tidligere aktivitet på Schibsted og samarbeidende mediehus sine nettsteder og apper.'
            : `${this.languages.whyexplained[this.locale]}`;

        this.smokeTestHTML = '';

        if (luckyUser()) {
            this.smokeTestHTML = `<div class='gdprAdTransparencySmokeTest'>
            <span class='gdprAdTransparencyModal__SmokeTestName'><a id='gdprAdTransparencyModal__hideAd' target='_blank' href="${this.languages.smokeTestURL[this.locale]}">${this.languages.smokeTest[this.locale]}</a><div id='gdprAdTransparencyModal_eyeIcon' class='gdprAdTransparencyModal__eyeIcon'></div></span>
            </div>`;
            this.languages.takecontrol.NO = '';
        }

        this.linksOverride = linksOverride;
        this.links = {
            notLoggedIn: {
                SV: {
                    settings: 'https://aboutme.schibsted.com/p/se/ads/%%%ENVIRONMENT_ID%%%?locale=sv_SE',
                    readmore: 'https://info.privacy.schibsted.com/se/s005/',
                },
                NO: {
                    settings: 'https://aboutme.schibsted.com/p/no/ads/%%%ENVIRONMENT_ID%%%?locale=nb_NO',
                    readmore: 'https://info.privacy.schibsted.com/no/s005/',
                },
            },
        };

        const { allowHidingAds } = getSiteConfig();
        this.allowHidingAds = this.config.allowHidingAds || allowHidingAds || false;
        this.hideAdsHTML = '';
        this.hiddenAdColor = '#DCE0E0';

        if (this.allowHidingAds) {
            this.hideAdsHTML = `<div class='gdprAdTransparencyHideAds'>
            <span class='gdprAdTransparencyModal__HideAdsLabel'>${this.languages.hideAd[this.locale]}</span><span class='gdprAdTransparencyModal__eyeIcon' title="${this.languages.hideAd[this.locale]}"></span>
            </div>`;
        }

        this.modal = this.createModal();
    }

    createModal() {
        const {
            locale,
            languages,
            titleText,
            whyText,
            whyexplainedText,
            smokeTestHTML,
            hideAdsHTML,
            allowHidingAds,
        } = this;

        const modal = document.createElement('div');
        const labelledById = 'gdprAdTransparencyModal__label';
        modal.setAttribute('role', 'dialog');
        modal.setAttribute('aria-modal', 'true');
        modal.setAttribute('aria-labelledby', labelledById);
        modal.setAttribute('style', 'display: none;');
        modal.classList.add('gdprAdTransparencyModal');
        if (allowHidingAds) {
            modal.classList.add('gdprAdTransparencyModal--hidingAds');
        }
        modal.id = 'gdprAdTransparencyModal';
        modal.innerHTML =
        `
        <div class='gdprAdTransparencyModal__content'>
            <div id='${labelledById}' class='gdprAdTransparencyModal__header'>
                <div id='gdprAdTransparencyModal__closeButton' class='gdprAdTransparencyModal__closeButton' aria-label="${languages.close[locale]}" role='button'></div>
                <div class='gdprAdTransparencyModal__headerSiteName'>${titleText}</div>
                <div class='gdprAdTransparencyModal__headerOrgName'>${languages.family[locale]}</div>
            </div>
            ${smokeTestHTML}
            ${hideAdsHTML}
            <div class='gdprAdTransparencyModal__infoboxSection'>
                <p class='gdprAdTransparencyModal__infoboxHeader'>${whyText}</p>
                <p class='gdprAdTransparencyModal__infoText'>${whyexplainedText}</p>
                <a href='${this.generateLinkSettings()}' target='_blank' rel='noopener' id='gdprAdTransparencyModal__linksSettings' class='gdprAdTransparencyModal__links'><span>${languages.manageheading[locale]}</span><span class='gdprAdTransparencyModal__linkIcon'></span></a>
            </div>
            <div class='gdprAdTransparencyModal__infoboxSection'>
                <p class='gdprAdTransparencyModal__infoboxHeader'>${languages.aboutschibsted[locale]}</p>
                <p class='gdprAdTransparencyModal__infoText'>${languages.schibstedexplain[locale]}</p>
                <div class='gdprAdTransparencyModal__readMoreLink'>
                    <a href='${this.generateLinksReadmore()}' target='_blank' rel='noopener' id='gdprAdTransparencyModal__linkReadMore' class='gdprAdTransparencyModal__links'><span>${languages.readmoreschibstedlinktext[locale]}</span><span class='gdprAdTransparencyModal__linkIcon'></span></a>
                </div>
            </div>
            <div class='gdprAdTransparencyModal__footer'>
                <a href="https://schibsted.com" target="_blank" rel="noopener" title="schibsted"></a>
                <p class="gdprAdTransparencyModal__creativeId">${languages.annonseId[locale]}: <span id="gdprAdTransparencyModal__creativeId"></span></p>
            </div>
        </div>
        `;
        const modalElement = document.body.appendChild(modal);
        const closeModal = document.getElementById('gdprAdTransparencyModal');
        const closeButton = document.getElementById('gdprAdTransparencyModal__closeButton');
        closeModal.onclick = this.handleClose;
        closeButton.onclick = this.handleCloseButton;
        return modalElement;
    }

    generateLinksReadmore = () => {
        const { locale, links, linksOverride: { readmore } = {} } = this;
        return readmore || `${links.notLoggedIn[locale].readmore}`;
    };

    generateLinkSettings = () => {
        const { locale, links, linksOverride: { settings } = {} } = this;
        return settings || `${links.notLoggedIn[locale].settings}`.replace('%%%ENVIRONMENT_ID%%%', getPulse2DataCookieUUID());
    };

    handleClick = ({ target }) => {
        if (!target.classList.contains('gdprAdTransparencyCogWheelButton')) {
            return;
        }
        this.modal.classList.add('gdprAdTransparencyModal--open');
        const settingsHref = document.getElementById('gdprAdTransparencyModal__linksSettings');
        settingsHref.href = this.generateLinkSettings();

        const targetId = this.getTargetIdFromClick(target);
        const creativeId = getCreativeId(this.apntag, targetId);

        this.setCreativeId(creativeId);

        const trackingParams = this.setupTracking(targetId, creativeId) || {};

        const hideAd = document.querySelector('.gdprAdTransparencyHideAds');
        if (hideAd) {
            hideAd.onclick = () => {
                sendPulseEvent('ClickedOnHideAd', trackingParams);
                this.handleHide(target);
            };
        }

        if (!this.tracker) {
            this.tracker = new PulseTracker();
        }

        pulse('init', 'mpt-advertising-solutions', null, null, 'cogwheel');

        sendPulseEvent('ClickedOnCogwheel', trackingParams);

        settingsHref.onclick = () => sendPulseEvent('ClickedOnSettingsLink', trackingParams);

        const gdprAdTransparencyModalLinkReadMore = document.getElementById('gdprAdTransparencyModal__linkReadMore');
        gdprAdTransparencyModalLinkReadMore.onclick = () => sendPulseEvent('ClickedOnInfoLink', trackingParams);
    };

    getTargetIdFromClick = target => target.dataset.target;

    setCreativeId = (creativeId) => {
        const creativeIdSpan = document.getElementById('gdprAdTransparencyModal__creativeId');
        if (creativeIdSpan) {
            creativeIdSpan.innerHTML = creativeId;
        }
    };

    handleHide = (target) => {
        const container = document.getElementById(target.dataset.target);

        if (container) {
            const dimensions = getElementDimensions(container);
            const placeholder = document.createElement('div');
            placeholder.style.minWidth = `${dimensions.width}px`;
            placeholder.style.height = `${dimensions.height}px`;
            placeholder.style.backgroundColor = this.hiddenAdColor;
            container.innerHTML = placeholder.outerHTML;
            this.closeModal();
        }
    };

    handleClose = ({ target }) => {
        if (!target.classList.contains('gdprAdTransparencyModal--open')) {
            return;
        }
        this.closeModal();
    };

    handleCloseButton = () => {
        this.closeModal();
    };

    closeModal = () => {
        const gdprCogwheelStylesExists = !!document.getElementById('gdprCogwheel');
        if (gdprCogwheelStylesExists && typeof (lazyStyles.use) !== 'undefined') {
            lazyStyles.unuse();
        }
        this.modal.classList.remove('gdprAdTransparencyModal--open');
    };

    setupTracking = (targetId, creativeId) => {
        const { invCode } = this.apntag.requests.tags[targetId];
        return { targetId, creativeId, invCode };
    }
}
