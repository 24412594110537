import AdTransparencyDecorator from './AdTransparencyDecorator';

function gdprCogwheelMain() {
    window.apntag = window.apntag || {};
    window.apntag.anq = window.apntag.anq || [];

    const { apntag } = window;
    const adDecorator = new AdTransparencyDecorator(apntag);
    adDecorator.decorateAds();
    apntag.anq.push(() => apntag.onEvent('adLoaded', adDecorator.adLoadedCallback));
}

const features = [];
const polyfills = [
    {
        function: Promise,
        polyfill: 'Promise',
    },
    {
        function: Element.prototype.remove,
        polyfill: 'Element.prototype.remove',
    },
];

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

polyfills.forEach((feature) => {
    if (typeof (feature.function) !== 'function') {
        features.push(feature.polyfill);
    }
});

if (features.length) {
    const s = document.createElement('script');

    // Include a `ua` argument set to a supported browser to skip UA identification
    // (improves response time) and avoid being treated as unknown UA (which would
    // otherwise result in no polyfills, even with `always`, if UA is unknown)
    // Run the main function via callback
    const reqFeatures = features.join(',');
    s.src = `https://polyfill.io/v3/polyfill.min.js?features=${reqFeatures}&flags=gated%2Calways&ua=chrome/50&callback=gdprCogwheelMain`;
    s.async = true;
    document.head.appendChild(s);
    gdprCogwheelMain();
} else {
    // Just do main
    gdprCogwheelMain();
}
