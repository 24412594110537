import siteConfig from './config';

const validLanguages = ['SV', 'NO', 'NB'];
const norwegianVariants = ['NO', 'NB'];

function getUrlParam(search, param) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    const params = {};
    hashes.forEach((hash) => {
        const [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });

    return params[param];
}

/**
 * The language in the HTML DOM element as a upper case 2 char string.
 * @returns {string} The language in the HTML DOM element or FALSE if missing
 */
function getHTMLLanguage() {
    const htmlTagLang = document.documentElement.lang.split('-')[0].toUpperCase();
    const validLang = htmlTagLang && validLanguages.some(lang => lang === htmlTagLang) ? htmlTagLang : false;
    return validLang;
}

function getMetaTagLanguage() {
    const metaContentLang = document.querySelector("meta[http-equiv='content-language']");
    const metaContentLangAttrid = metaContentLang && metaContentLang.getAttribute('content').split('-')[0].toUpperCase();
    return metaContentLangAttrid && validLanguages.some(lang => lang === metaContentLangAttrid) ? metaContentLangAttrid : false;
}

function getMetaNameLanguage() {
    const langMeta = document.querySelector("meta[name='language']");
    const langMetaAttrib = langMeta && langMeta.getAttribute('content').split('-')[0].toUpperCase();
    return langMetaAttrib && validLanguages.some(lang => lang === langMetaAttrib) ? langMetaAttrib : false;
}

function getGETLang() {
    if (process.env.NODE_ENV !== 'development') {
        return false;
    }
    const queryParamLang = getUrlParam(window.location.search, 'gdprLang');
    return queryParamLang && validLanguages.some(lang => lang === queryParamLang) ? queryParamLang : false;
}

function getFromContstants() {
    const { constants } = window;
    if (typeof (constants) === 'object') {
        const { constants: { LANGUAGE } = {} } = window;
        if (typeof (LANGUAGE) === 'string') {
            const varLang = LANGUAGE.toUpperCase();
            return varLang && validLanguages.some(lang => lang === varLang) ? varLang : false;
        }
    }
    return false;
}

const endsWithNo = /\.no$/;

function getTLD() {
    // String.prototype.endsWith() not supported in IE.
    return endsWithNo.test(document.location.host) ? 'NO' : 'SV';
}

export function getSiteConfig() {
/**
    if (process.env.NODE_ENV === 'development') {
        const gdprSitename = getUrlParam(window.location.search, 'gdprSitename');
        const customColorValue = gdprSitename && siteConfig[gdprSitename].useCustomColor ? siteConfig[gdprSitename].useCustomColor : '#4a4a4a';
        const percentChanceOfBVersion = gdprSitename && siteConfig[gdprSitename].percentChanceOfBVersion ? siteConfig[gdprSitename].percentChanceOfBVersion : 0;
        const pulseID = gdprSitename && siteConfig[gdprSitename].pulseID ? siteConfig[gdprSitename].pulseID : '';
        const allowHidingAds = gdprSitename && siteConfig[gdprSitename].allowHidingAds ? siteConfig[gdprSitename].allowHidingAds : '';

        return {
            name: gdprSitename,
            useCustomColor: customColorValue,
            percentChanceOfBVersion,
            pulseID,
            allowHidingAds,
        };

    }
*/
    const parts = document.location.host.split('.');
    const host = parts[parts.length - 2];
    const config = siteConfig[host];

    if (config) {
        if (!config.name) {
            config.name = host;
        }
        return config;
    } else {
        const unconfig = {
            name: host,
            pulseID: host,
        };
        return unconfig;
    }
}

export const getLocale = () => {
    let locale = getGETLang() || getHTMLLanguage() || getMetaTagLanguage() || getMetaNameLanguage() || getFromContstants() || getTLD() || 'SV';

    // Array.prototype.includes() not supported in IE.
    locale = norwegianVariants.some(variant => variant === locale) ? 'NO' : 'SV';
    return locale;
};

/**
 * @returns {string} The UUID aka Environment-Id in the cookie named _pulse2data that is set on site by "Schibsted Pulse script"
 */
export const getPulse2DataCookieUUID = () => {
    /* eslint-disable */
    const cookie = decodeURIComponent(
        document.cookie.replace(
            new RegExp(
                '(?:(?:^|.*;)\\s*' + encodeURIComponent('_pulse2data').replace(/[\-\.\+\*]/g, '\\$&'
            ) + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1'
        )
    ) || false;
    if (cookie) {
        return cookie.split(',')[0];
    }
    return;
    /* eslint-enable */
};

/**
 * @param {number} max Size of pool to choose from
 * @returns {number} Randomn number between 0 and max
 */
function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

/**
 * @returns {boolean} Should they get the Smoke Test.
 */
export function luckyUser() {
    if (getUrlParam(window.location.search, 'luckycogwheeluser')) {
        return true;
    }
    const { percentChanceOfBVersion = 0 } = getSiteConfig();
    return getRandomInt(100) < percentChanceOfBVersion;
}

/**
 * We need a way to specify the exact string used as an identfier in Pulse
 * "sdrn:schibsted:client:finn", "sdrn:schibsted:client:SVDSE" etc
 *
 * @returns {string} The string used to identify in Pulse
 */
export function getPulseID() {
    const { pulseID, name } = getSiteConfig();

    const providerId = `sdrn:schibsted:client:${pulseID || name}`;
    return providerId;
}

/**
 * @param {string} action Thing that was clicked on
 * @param {object} trackingParams params object
 * @returns {boolean} empty
 */
export function sendPulseEvent(action, trackingParams) {

    const sdrn = getPulseID();

    window.pulse('cogwheel.trackPageView', {
        type: 'Engagement',
        engagement: 'UIElement',
        object: {
            id: trackingParams.targetId,
            name: action,
            custom: {
                targetId: trackingParams.targetId,
                creativeId: trackingParams.creativeId,
                invCode: trackingParams.invCode,
                source: window.location.href,
                providerId: sdrn,
            },
        },
    });
    return true;
}

/**
 * @param {HTMLElement} element HTMLElement to get dimensions from
 * @returns {{width: *, height: *}} object containing element dimensions
 */
export function getElementDimensions(element) {
    const computedStyle = getComputedStyle(element);
    const computedStyleBefore = getComputedStyle(element, ':before');

    let elementHeight = element.clientHeight;
    let elementWidth = element.clientWidth;

    elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom) + parseFloat(computedStyleBefore.height);
    elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

    return {
        height: elementHeight,
        width: elementWidth,
    };
}

/**
 * @param {object} apntag Appnexus' apntag object
 * @param {string} placementId Id of ad placement
 * @returns {string} creativeId
 */
export function getCreativeId(apntag, placementId) {
    try {
        const { adResponse: { ads = [{}] } } = apntag.requests.tags[placementId];
        return ads[ads.length - 1].creative_id;
    } catch (e) {
        return null;
    }
}
