/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
export default class PulseTracker {
    constructor() {
        (function (w, d, n, t, s, a, b) {
            w[n] = w[n] || function () {
                (window[n].q = window[n].q || []).push(arguments);
            };
            a = d.createElement(t);
            [b] = document.getElementsByTagName(t);
            a.async = 1;
            a.src = s;
            if (b) {
                b.parentNode.insertBefore(a, b);
            }
        }(window, document, 'pulse', 'script', 'https://sdk.pulse.schibsted.com/pulse-modern.min.js'));
    }
}
