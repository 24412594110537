import lazyStyles from './styles/modal.lazy.scss';

export const baseStyle = 'height: 26px; width: 26px; cursor: pointer;';
const safeframeStyles = 'position: absolute; top: 0px; left: 0px; z-index: 1000; background-color: rgba(255, 255, 255, 0.75); border-bottom-right-radius: 13px;';
const predefinedElementStyles = 'position: absolute; top: 0px; left: 0px;';
export const friendlyIframeStyles = 'position: absolute; top: 0px; left: 0px; z-index: 1000; background-color: rgba(255, 255, 255, 0.75); border-bottom-right-radius: 13px;';
export const iconStyles = 'pointer-events: none; height: 15px; width: 15px; position: absolute; top: 5px; left: 5px; background-repeat: no-repeat; line-height: 15px;';
const cogWheel = '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M13.8 11.8l-.1-.3-1-1.3.3-.9 1.8-.3.2-.1.1-.2V6.5v-.3h-.3L13 5.8a8 8 0 0 0-.4-1 13.3 13.3 0 0 1 1-1.4l.1-.2c0-.2-.5-.8-1.7-1.9h-.5l-1.3 1-1-.3L9 .3 8.9 0 8.6 0H6.5c-.2 0-.3 0-.4.3L5.8 2l-.9.4-1.3-1-.3-.2-1 .7-.9 1v.6l1 1.3-.3.9-1.8.3-.2.1-.1.2V9h.3l1.8.3c0 .3.2.7.4 1a22.3 22.3 0 0 1-1 1.4l-.1.2c0 .2.5.8 1.7 1.9h.5l1.3-1 1 .3.2 1.8.1.2.3.1h2.1c.2 0 .3 0 .4-.3l.3-1.8.9-.4 1.3 1 .3.2 1-.7.9-1v-.3zM9.7 9.7a3 3 0 0 1-2.1.9 3 3 0 0 1-2.2-1 3 3 0 0 1-.9-2c0-.9.3-1.6 1-2.2a3 3 0 0 1 2-.9 3 3 0 0 1 2.2 1c.6.5.9 1.2.9 2a3 3 0 0 1-1 2.2z" fill="#4a4a4a" fill-rule="evenodd"/></svg>';
export default class CogWheelButton {
    constructor(id, type, useCustomColor, ariaLabelWhyLocale) {

        const cogWheelIcon = useCustomColor ? cogWheel.replace('#4a4a4a', useCustomColor) : cogWheel;

        this.icon = cogWheelIcon;
        this.type = type;

        const iconElement = this.createIconElement();
        this.button = document.createElement('div');
        this.button.setAttribute('role', 'button');
        this.button.setAttribute('aria-label', ariaLabelWhyLocale);
        this.button.id = `gdprAdTransparencyCogWheelButton-${id}`;
        this.button.className = 'gdprAdTransparencyCogWheelButton';
        this.button.dataset.target = id;
        // assignment to read-only properties (Element.style) not allowed in strict mode.
        this.button.style.cssText = this.createInlineStyles();
        this.button.appendChild(iconElement);
        this.button.addEventListener('click', () => {
            const gdprCogwheelStylesExists = !!document.getElementById('gdprCogwheel');
            if (!gdprCogwheelStylesExists && typeof (lazyStyles.use) !== 'undefined') {
                lazyStyles.use();
            }
        });

    }

    getCustomStyles = () => {
        switch (this.type) {
            case 'safeframe':
                return safeframeStyles;
            case 'predefinedElement':
                return predefinedElementStyles;
            default:
                return friendlyIframeStyles;
        }
    };

    createInlineStyles = () => `${baseStyle} ${this.getCustomStyles()}`;

    createIconElement = () => {
        const icon = document.createElement('div');
        icon.innerHTML = this.icon;
        icon.style.cssText = iconStyles;
        icon.classList.add('gdprAdTransparencyCogWheelButton');
        icon.classList.add('gdprAdTransparencyCogWheelButton__icon');
        return icon;
    };

    getButton = () => this.button;

}
